import Swiper from "swiper/bundle";

export default function swiperGallery() {
  const initSwiperDetail01 = () => {
    const swiper = new Swiper(".js-swiper-detail01", { // eslint-disable-line no-unused-vars
      loop: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  };

  const initSwiperDetail02 = () => {
    const swiper = new Swiper(".js-swiper-detail02", { // eslint-disable-line no-unused-vars
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  };

  const initSwiperTop = () => {
    const swiper = new Swiper(".js-swiper-top", { // eslint-disable-line no-unused-vars
      loop: true,
      effect: 'fade',
      autoplay: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  };

  initSwiperDetail01();
  initSwiperDetail02();
  initSwiperTop();
}
