import { throttle } from 'throttle-debounce';

import smoothScroll from './modules/smoothScroll';
import togglePanel from './modules/togglePanel';
import globalMenuSP from './modules/globalMenuSP';
import confirmCheckbox from './modules/confirmCheckbox';
import scrollPosition from './modules/scrollPosition';
import swiper from './modules/swiper';

const menuBtn = document.querySelector('#js-menu');
const menuPanel = document.querySelector('#js-gnav');

function checkBreakPoint() {
  if (window.matchMedia('(max-width: 767px)').matches) {
    menuPanel.style.display = 'none';
    menuPanel.style.opacity = 0;
    menuBtn.setAttribute('aria-expanded', 'false');
    menuBtn.setAttribute('aria-label', 'メニューを開く');
    menuPanel.setAttribute('aria-hidden', 'true');
  }
}

window.addEventListener('resize', throttle(200, function () {
  checkBreakPoint();
  smoothScroll();
}), false);

checkBreakPoint();
smoothScroll();
globalMenuSP();
togglePanel();
confirmCheckbox();
scrollPosition();
swiper();
