import { fadeIn, fadeOut } from './fade';

export default function globalMenuSP() {
  const html = document.querySelector('html');
  const menuBtn = document.querySelector('#js-menu');
  const menuPanel = document.querySelector('#js-gnav');
  menuBtn.addEventListener('click', () => {
    if ('true' === menuBtn.getAttribute('aria-expanded')) {
      menuBtn.setAttribute('aria-expanded', 'false');
      menuBtn.setAttribute('aria-label', 'メニューを開く');
      menuPanel.setAttribute('aria-hidden', 'true');
      html.classList.remove('is-open');
      fadeOut(menuPanel);
    } else {
      menuBtn.setAttribute('aria-expanded', 'true');
      menuBtn.setAttribute('aria-label', 'メニューを閉じる');
      menuPanel.setAttribute('aria-hidden', 'false');
      html.classList.add('is-open');
      fadeIn(menuPanel);
    }
  });
}
