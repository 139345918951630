export default function confirmCheckbox() {
  const confirm = document.querySelector('.aform-checkbox-ul input');
  const submit = document.querySelector('.aform-button-area input');
  if (confirm) {
    confirm.checked = false;
    submit.setAttribute('disabled', true);
    confirm.addEventListener('click', () => {
      if (confirm.checked) {
        submit.removeAttribute('disabled');
      } else {
        submit.setAttribute('disabled', true);
      }
    });
  }
}
