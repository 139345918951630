export default function smoothScroll() {
  const header = document.getElementById('js-header');
  const smoothScrollTriggers = document.querySelectorAll('a[href^="#"]');
  const offset = header.clientHeight + 30;
  smoothScrollTriggers.forEach(function (smoothScrollTrigger) {
    smoothScrollTrigger.addEventListener('click', function (e) {
      const href = smoothScrollTrigger.getAttribute('href');
      const targetElement = (href === "#") ? document.getElementsByTagName('html')[ 0 ] : document.getElementById(href.replace('#', ''));
      if (targetElement) {
        e.preventDefault();
        e.stopPropagation();
        const targetPosition = window.pageYOffset + targetElement.getBoundingClientRect().top - offset;
        window.scroll({
          top: targetPosition,
          behavior: 'smooth'
        });
      }
    });
  });
}
