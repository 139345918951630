import { slideUp, slideDown } from 'simple-slide-toggle';

export default function togglePanel() {
  const toggleBtn = document.querySelectorAll('.js-toggle');
  toggleBtn.forEach(function (button) {
    const toggleP = button.previousElementSibling;
    button.addEventListener('click', () => {
      if ('true' === button.getAttribute('aria-expanded')) {
        button.setAttribute('aria-expanded', 'false');
        button.innerText = '一覧を見る';
        button.classList.replace('_up', '_down');
        toggleP.setAttribute('aria-hidden', 'true');
        slideUp(toggleP, 500);
      } else {
        button.setAttribute('aria-expanded', 'true');
        button.innerText = '閉じる';
        button.classList.replace('_down', '_up');
        toggleP.setAttribute('aria-hidden', 'false');
        slideDown(toggleP, 500);
      }
    });
  });
}
