//add is-scroll class(for back to page top button & srink header)
export default function scrollPosition() {
  const body = document.querySelector('body');
  let scrollPx = window.scrollY;
  window.addEventListener('scroll',
    () => {
      scrollPx = window.scrollY;

      if (scrollPx > 120) {
        body.classList.add('is-scroll');
      } else {
        body.classList.remove('is-scroll');
      }
    }, {
      passive: true
    }
  );
}
